:root{
    --font-size : 14px;
    --font-sizeval : 12px;
}
/* .MainDashboardContainer{
    background-color: black;
} */
.headerDashMain{
    display: flex;

}
.sidemanubar{
    width: 7%;
    margin: 5px;
}
.activetodaycontainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
}
.activestatusmain{
    width: 50%;
    min-height: 50vh;
    margin: 0.1rem;
    display: flex;
    flex-direction: column;
}
.headeractivestatus{
    background-color: var(--backColor);
    /* background-color: blue; */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    padding: 0.5rem;
    flex-wrap: wrap;
    width: 93%;
}
.headeractivestatus .avtivestatusroomname{
    padding-right: 2rem;
}

.headeractivestatus .activestatuslogo{
    display: flex;
    justify-content: center;
    align-items: center;

} 
.headeractivestatus .livestatuslogo{
    padding: 0 0.5rem;
}


@media screen and (max-width:1100px) {
    .headerDashMain{
        flex-direction: column;
    
    }
    .headeractivestatus{
        width: 100%;
        }
}