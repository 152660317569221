@import "rsuite/dist/rsuite.css";
/* .MainDashboardContainer{
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    min-height: calc(100vh - 40px);
} */
.LastMonthStatus{
    margin-top: 1rem;
}
.inputLotReport{
    color: white !important;
    /* background-color: transparent;
    border: 1px solid white; */
}
.inputLotReport_label{
    color: white !important;
}

.MuiOutlinedInput-notchedOutline{
    border-color:  white !important;
  }


.datepiker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.rangepiker {
    width: 14rem;
}
.btnreport {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    padding: 2px 10px;
    border-radius: 5px;
    margin: 0 0.5rem;
    width: 4rem;
    margin: 0.3rem 0rem;
    font-size: 0.7rem;
    margin-left: 2rem;

}

.btnreport:hover {
    border: 1px solid white;
    color: var(--backColor);
    background-color:white;
}
