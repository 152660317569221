.loginuserimage{
    height: 5rem;
    width: 5rem;
}
.loginmainContainer{
    height: 100vh;
    width: 100%;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .loginmain{
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    min-height: calc(100vh - 65px); 
  }
.logincontainer{
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center;
    align-items: center;
}
.logincontainer .loginform{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 15rem;
    width: 20rem;
    border-radius: 10px;
    padding: 2rem;
}
.logincontainer .headinglogin{
    font-size: 2rem;
    padding-bottom: 1rem;
}

.logincontainer .logininput{
    padding: 0.4rem;

    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    border-bottom: 1px solid gray;
    cursor: text;
    margin-bottom: 1rem;
    width: 15rem;
    color: gray;
}
input[type="text"]:focus{
  background-color: transparent;
  color: gray;
}
input[type="text"]{
  background-color: transparent;
  color: gray;
}
input[type="password"]::placeholder{
  background-color: transparent;
  color: gray;
}
input[type="text"]::placeholder{
  background-color: transparent;
  color: gray;

}

.logincontainer .btnlogin{
    margin-top: 5px;
    align-self: flex-end;
    background-color: transparent;
    border: 1px solid gray;
    padding: 2px 7px;
    width: 4rem;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-right: 1rem;


}
.logincontainer .btnlogin:hover{
    background-color: var(--backColor);
    color: white;
}

@media screen and (max-width:500px) {
  .loginmain{
    min-height: calc(100vh - 120px); 
  }
}