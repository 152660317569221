:root {
    --font-size: 14px;
    --font-sizeval: 12px;
}

/* .MainDashboardContainer{
    background-color: black;
} */

.activetodaycontainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
}

.activestatusmain {
    width: 50%;
    min-height: 50vh;
    margin: 0.1rem;
    display: flex;
    flex-direction: column;
}

.activestatusmain .tablegauge {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 1rem;
    height: 13rem;
    gap: 2rem;
    flex-wrap: wrap;
}

.activestatusmain .tableMain tr,
th,
td {
    border: 1px solid var(--backColor);
    font-size: 12px;
}

.activestatusmain .tableMain {
    width: fit-content;
}

.activestatusmain .tableMain td {
    padding: 0.1rem 1rem;
}

.activestatusmain .tableMain th {
    background-color: var(--backColor);
    color: white;
}
/* .activestatusmain .genOffStatus{
    color: red;
} */

.headeractivestatus {
    background-color: var(--backColor);
    /* background-color: blue; */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    padding: 0.5rem;
    flex-wrap: wrap;
}

.headeractivestatus .avtivestatusroomname {
    padding-right: 2rem;
}

.headeractivestatus .activestatuslogo {
    display: flex;
    justify-content: center;
    align-items: center;

}

.headeractivestatus .livestatuslogo {
    padding: 0 0.5rem;
}

.activevalues {
    display: flex;
    justify-content: flex-start;
    font-size: var(--font-sizeval);
    flex-wrap: wrap;
}

.activevalues .valuemain {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}

.activevalues .valueName {
    color: var(--backColor);
    margin: 5px 0;
    font-weight: bolder;
}

/* .activevalues .valuee{
    margin-top: 0.4rem;
} */
.activevalues .val {
    padding: 5px 10px;
    border-radius: 3px;
    color: white;
    font-size: 13px;
}

.activevalues .val1 {
    background-color: #00bcd4;
}

.activevalues .val2 {
    background-color: #2196f3;
}

.activevalues .val3 {
    background-color: #9c27b0;
}

.activevalues .val4 {
    background-color: chocolate;
}

.activevalues .val5 {
    background-color: chocolate;
}

.activevalues .val6 {
    background-color: #2196f3;
}

.activevalues .val7 {
    background-color: #9c27b0;
}

.datetimestatus {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 5px;
    font-size: 13px;
}

.chartunitgraph {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.areachart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

@media screen and (max-width:500px) {
    .activestatusmain .tablegauge {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 1rem;
        height: 7rem;
    }

    .activestatusmain .tableMain tr,
    th,
    td {
        font-size: 10px;
    }
}

@media screen and (max-width:900px) {
    .activetodaycontainer {
        flex-direction: column;
    }

    .activestatusmain {
        width: 100%;
    }
}
@media screen and (max-width:1040px) {
    .activestatusmain .tablegauge {
        height: fit-content;
    }
}